import React, { useEffect, useRef } from "react";
import { Container, Button } from "react-bootstrap";

const Hero = () => {
  return (
    <div
      className="text-white text-center py-5"
      style={{
        position: "relative",
        overflow: "hidden",
        height: "100vh", // Full viewport height
      }}
    >
      <div>
        <h1>Hello, I'm Earl!</h1>
        <p>
          I'm a Software Engineer specializing in back-end development with 7
          years of experience.
        </p>
        <Button variant="light" href="#projects">
          View My Work
        </Button>
      </div>
    </div>
  );
};

export default Hero;
