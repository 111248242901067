import React, { useRef, useEffect } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import About from "./components/About";
import Contact from "./components/Contact";
import runEffect from "./components/effect/CanvasEffect";

function App() {
  const canvasRef = useRef(null);

  useEffect(() => {
    runEffect(canvasRef.current);
  }, []);

  return (
    <div
      className="text-white text-center"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <canvas
        ref={canvasRef}
        style={{
          backgroundImage:
            "radial-gradient(ellipse farthest-corner at center top, hsla(230, 100%, 15%, 1) 0%, hsla(231, 90%, 1%, 1) 100%)",
          position: "absolute", // Position it absolutely to cover the container
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1, // Ensure it's behind other content
        }}
      />
      <Header />
      <Hero />
      <Projects />
      <About />
      <Contact />
    </div>
  );
}

export default App;
