import React from "react";
import { Container } from "react-bootstrap";

const About = () => {
  return (
    <Container
      id="about"
      className="p-5"
      style={{
        position: "relative",
        overflow: "hidden",
        height: "100vh", // Full viewport height
      }}
    >
      <h2>About Me</h2>
      <p>
        I am a Software Engineer with 7 years of experience in backend
        development, specializing in Java, JavaScript, and cloud technologies
        like AWS and Azure. My passion is building scalable solutions for
        complex business needs.
      </p>
    </Container>
  );
};

export default About;
