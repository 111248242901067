import React from "react";
import { Container } from "react-bootstrap";

const Contact = () => {
  return (
    <Container id="contact" className="p-5 text-center">
      <h2>Contact</h2>
      <p>
        Email: <a href="mailto:earl.june.o@gmail.com">earl.june.o@gmail.com</a>
      </p>
      <p>
        LinkedIn:{" "}
        <a href="https://www.linkedin.com/in/earljuneomictin/">
          https://www.linkedin.com/in/earljuneomictin/
        </a>
      </p>
    </Container>
  );
};

export default Contact;
