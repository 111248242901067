import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";

const projects = [
  {
    title: "Project 1",
    description: "A web app that solves XYZ problem.",
    link: "#",
  },
  {
    title: "Project 2",
    description: "An API that improves ABC efficiency.",
    link: "#",
  },
  // Add more projects as needed
];

const ProjectCard = ({ title, description, link }) => {
  return (
    <Card style={{ width: "100%", margin: "1rem" }}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        <Button variant="primary" href={link}>
          View Project
        </Button>
      </Card.Body>
    </Card>
  );
};

const Projects = () => {
  return (
    <div
      id="projects"
      className="p-5"
      style={{
        position: "relative",
        overflow: "hidden",
        height: "100vh", // Full viewport height
      }}
    >
      <h2 className="text-center mb-4">Projects</h2>
      <Row>
        {projects.map((p, i) => (
          <Col md={6} key={i} className="mb-4">
            <ProjectCard
              title={p.title}
              description={p.description}
              link={p.link}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Projects;
